/**
 * @generated SignedSource<<7e4d56b577a81adc44dd0e3a15a87da2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseContentSponsorContent$data = {
  readonly sponsorType?: string | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly entityId: string;
    readonly sponsor: {
      readonly name: string | null | undefined;
      readonly type: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"sponsorTagSponsor">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsMainTopicContent">;
  readonly " $fragmentType": "hooksUseContentSponsorContent";
};
export type hooksUseContentSponsorContent$key = {
  readonly " $data"?: hooksUseContentSponsorContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseContentSponsorContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseContentSponsorContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Sponsor",
          "kind": "LinkedField",
          "name": "sponsor",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "sponsorTagSponsor"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sponsorType",
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsMainTopicContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "afcc74ae7531bf4065513a531974e530";

export default node;
