import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentSchemaRender } from "~/components/schema-render/content";
import type { contentSubHeadlineContent$key } from "~/queries/__generated__/contentSubHeadlineContent.graphql";

import { ContentSubHeadlineContainer } from "./styles";

export type Props = {
  className?: string;
  reference: contentSubHeadlineContent$key;
  variant?: "magazines-style";
};

export const ContentSubHeadline: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  ...attribs
}) => {
  const content = useFragment(
    graphql`
      fragment contentSubHeadlineContent on Content {
        subHeadline {
          json
        }
        summary {
          json
        }
        ...contentSchemaRenderContent
      }
    `,
    reference_,
  );

  if (!content.subHeadline?.json?.length && !content.summary?.json?.length) return null;

  return (
    <ContentSubHeadlineContainer $variant={attribs.variant} className={className} {...attribs}>
      <ContentSchemaRender
        reference={content}
        schema={
          notEmpty(content.subHeadline?.json) ? content.subHeadline?.json : content.summary?.json
        }
      />
    </ContentSubHeadlineContainer>
  );
};

ContentSubHeadline.displayName = "ContentSubHeadline";
