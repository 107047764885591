import { graphql, useFragment } from "react-relay";

import { checkIsMainTopic } from "~/components/content/helpers";
import type { hooksUseContentSponsorContent$key } from "~/queries/__generated__/hooksUseContentSponsorContent.graphql";

// Need to check article.sponsorType to determine it's sponsor article because AMS would like to sell a topic index page
// Reference: https://scmp.slack.com/archives/C02S11PDGMD/p1661220542403959?thread_ts=1661219763.499569&cid=C02S11PDGMD
export const useContentSponsor = (reference_: hooksUseContentSponsorContent$key) => {
  const data = useFragment(
    graphql`
      fragment hooksUseContentSponsorContent on Content {
        topics {
          entityId
          sponsor {
            ...sponsorTagSponsor
            name
            type
          }
        }
        ... on Article {
          sponsorType
        }
        ...helpersCheckIsMainTopicContent
      }
    `,
    reference_,
  );

  const mainTopic = data?.topics?.find?.(topic => checkIsMainTopic(data, topic?.entityId));
  const sponsorName = mainTopic?.sponsor?.name;
  const sponsorType = mainTopic?.sponsor?.type;
  const shouldShowSponsor = sponsorName && sponsorType && data.sponsorType;
  if (!shouldShowSponsor) return;

  return mainTopic.sponsor;
};
