import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import qs from "qs";

import { config } from "~/data";

import type { BookmarkActionResponse, BookmarkPayload, GetBookmarksResponse } from "./types";
import { BookmarkTypes } from "./types";
import { generateNewList } from "./utils";

const defaultConfig: AxiosRequestConfig = {
  withCredentials: true,
};

export const fetchList = async () => {
  const query = qs.stringify({ detail: false });

  const resp = await axios.get<GetBookmarksResponse>(
    `${config.general.userFollowHost}/user-bookmark-list?${query}`,
    {
      ...defaultConfig,
    },
  );

  return resp.data.userBookmarkList;
};

export const create = async (
  previousList: GetBookmarksResponse["userBookmarkList"] | undefined,
  parameter: BookmarkPayload,
) => {
  const _ = await axios.post<BookmarkActionResponse>(
    `${config.general.userFollowHost}/bookmark`,
    {
      messageItem: {
        type: BookmarkTypes.Node,
        ...parameter,
      },
    },
    {
      ...defaultConfig,
    },
  );

  return generateNewList(previousList, parameter);
};
