import { graphql, readInlineData } from "react-relay";

import { section as dataSection } from "~/data/section";
import type { helpersFilterSection$key } from "~/queries/__generated__/helpersFilterSection.graphql";

export const checkIfNewsSectionInFirstIndex = (
  section_: Nullish<helpersFilterSection$key>,
  index: number,
) => {
  if (!section_) return false;

  const section = readInlineData(
    graphql`
      fragment helpersFilterSection on Section @inline {
        name
      }
    `,
    section_,
  );
  return index === 0 && section?.name === dataSection.news.name;
};
