/**
 * @generated SignedSource<<57af14c61d21cf5691fcaf059347d4f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type authorAvatarAuthor$data = {
  readonly entityId: string;
  readonly image: {
    readonly style: {
      readonly url: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly types: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  readonly " $fragmentType": "authorAvatarAuthor";
};
export type authorAvatarAuthor$key = {
  readonly " $data"?: authorAvatarAuthor$data;
  readonly " $fragmentSpreads": FragmentRefs<"authorAvatarAuthor">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "authorAvatarAuthor",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "types",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "type": "AUTHOR_IMAGE"
          }
        }
      ],
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "118x118"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": "style(filter:{\"style\":\"118x118\"})"
        }
      ],
      "storageKey": "image(filter:{\"type\":\"AUTHOR_IMAGE\"})"
    }
  ],
  "type": "Author",
  "abstractKey": null
};

(node as any).hash = "de670963dc1012fb880848c38f387527";

export default node;
