import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

import { BaseLink } from "~/components/common/base-link";

export const StyledSnackbarMessage = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-family: ${fontRoboto};
`;

export const StyledSnackbarAction = styled(BaseLink)`
  color: #4585ff;
  font-weight: 900;
  font-size: 16px;
  font-family: ${fontRoboto};
`;
