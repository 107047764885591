/**
 * @generated SignedSource<<a915889f13d94b29445970052b66f3dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentLiveTagContent$data = {
  readonly flattenTypeIds: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly liveArticle: {
    readonly status: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "contentLiveTagContent";
};
export type contentLiveTagContent$key = {
  readonly " $data"?: contentLiveTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentLiveTagContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentLiveTagContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flattenTypeIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LiveArticle",
      "kind": "LinkedField",
      "name": "liveArticle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "83cbbe828606a97acf9a81d061785aae";

export default node;
