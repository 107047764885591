/**
 * @generated SignedSource<<7eca0e8739eac59f66def650bd69daf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersFilterSection$data = {
  readonly name: string;
  readonly " $fragmentType": "helpersFilterSection";
};
export type helpersFilterSection$key = {
  readonly " $data"?: helpersFilterSection$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersFilterSection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersFilterSection"
};

(node as any).hash = "fa05746b871613479b5729dffb52dcee";

export default node;
