import styled from "@emotion/styled";
import { fontMerriweather } from "@product/scmp-sdk";

export const LiveTag = styled.div`
  display: inline-flex;
  flex-flow: row;
  gap: 4px;
  justify-content: center;
  align-items: center;

  padding-block: 8px;
  padding-inline: 6px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontMerriweather};
  line-height: 12px;

  background-color: #ffca05;

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  &:before {
    content: "";

    display: inline-block;

    inline-size: 12px;
    block-size: 12px;

    border-radius: 50%;

    background-color: #ffffff;

    animation: fadeInOut 1s infinite;
  }
`;
