import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontPlayfairDisplay, fontRoboto } from "@product/scmp-sdk";

import { Tooltip, tooltipClasses } from "~/components/common/tooltip";

export const StyledTooltip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    padding: 20px;

    color: #222222;

    border: 1px solid #cccccc;
    border-radius: 4px;

    background-color: #f6f6f6;
  }

  & .${tooltipClasses.arrow} {
    color: #f6f6f6;
    font-size: 20px;
    &::before {
      border: 1px solid #cccccc;
    }
  }
`;

export const Heading = styled.span`
  display: inline;

  padding-inline-end: 10px;

  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
`;

export const Description = styled.span`
  display: inline;

  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 18px;
`;

export const FlagDivider = styled.span``;

type FlagProps = {
  $variant?: "magazines-style";
};
export const Flag = styled.span<FlagProps>`
  color: ${props => props.color ?? "#666666"};
  text-transform: capitalize;

  ${props =>
    props.$variant === "magazines-style" &&
    css`
      color: #222222;
      font-family: ${fontPlayfairDisplay};
      text-transform: uppercase;
      span {
        font-style: italic;
      }

      ${FlagDivider} {
        font-style: normal;
      }
    `}
`;
