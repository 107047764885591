import { PageType, useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import qs from "qs";
import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";

import { useSnackbarSimple } from "~/components/common/snackbar/hooks";
import { bookmarkAtom } from "~/components/header/header-bookmark/atoms";
import { useLoginDialogStateHelper } from "~/components/login-dialog/hooks";
import type { BookmarkArticleLocation } from "~/components/mixpanel";
import { registerMixPanelParameters, trackMixPanel, useMixpanel } from "~/components/mixpanel";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import type { Event, UntypedEvent } from "~/components/tracking/google-analytics/types";
import { sendGA4Tracking } from "~/components/tracking/google-analytics-4/apis";
import type { Event as GA4Event } from "~/components/tracking/google-analytics-4/types";
import { config } from "~/data";
import { accountAtom } from "~/lib/account/atoms";
import { useDeferredAction } from "~/lib/hooks";
import { useCurrentPageType } from "~/lib/router/hooks";
import { addMinutes } from "~/lib/utils";

import { create, fetchList } from "./apis";
import { BookmarkedContentListSwrFetchKey, BookmarkedLastSyncKey } from "./consts";
import { StyledSnackbarAction, StyledSnackbarMessage } from "./styles";
import type {
  BookmarkActions,
  BookmarkLocation,
  BookmarkPayload,
  BookmarkQueryStringPayload,
} from "./types";
import { generateNewList } from "./utils";

export const useBookmark = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const { set: setLastSync, value: lastSync } = useFallbackStorageValue(BookmarkedLastSyncKey, {
    defaultValue: 0,
  });
  const getIsDataOutDated = () => Date.now() > addMinutes(lastSync ?? 0, 10).getTime();
  const { data, mutate } = useSWRImmutable(
    isLoggedIn ? BookmarkedContentListSwrFetchKey : null,
    fetchList,
    {
      isPaused: () => !getIsDataOutDated(),
      onSuccess: () => setLastSync(Date.now()),
      refreshInterval: 1000 * 30,
      revalidateOnMount: true,
    },
  );

  const currentPageType = useCurrentPageType();
  const mixpanel = useMixpanel();

  const handleMixpanelTracking = useCallback(
    (action: BookmarkActions, entityId: string, trackings?: { location?: BookmarkLocation }) => {
      const computePageLocation: () => BookmarkArticleLocation | null = () => {
        switch (currentPageType) {
          case PageType.Article:
            switch (trackings?.location) {
              case "header":
                return "Article Adhesive";
              case "side":
                return "Article Side";
              case "others":
                return "Article";
              default:
                return null;
            }
          case PageType.Author:
            return "Author Page";
          case PageType.Homepage:
            return "Homepage";
          case PageType.Others:
            return "Other Index";
          case PageType.Section:
            return "Section";
          case PageType.Topic:
            return "Topic Index";
          default:
            return null;
        }
      };

      const pageLocation = computePageLocation();
      if (!pageLocation) return;
      registerMixPanelParameters(mixpanel.result, {
        "Article ID": entityId,
        Location: pageLocation,
      });
      trackMixPanel(mixpanel.result, {
        name: "Bookmark Article",
        property: {
          Action: action === "bookmark" ? "Add" : "Remove",
          Location: pageLocation,
        },
      });
    },
    [currentPageType, mixpanel.result],
  );

  const { deferRun } = useDeferredAction([!!data]);
  const { handleOpenSnackbar } = useSnackbarSimple();
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { toggleBookmarkRippleEffect, toggleShowNewBookmarkAnimation } = useAtomValue(bookmarkAtom);
  const handleBookmarkAction = useCallback(
    <Untyped extends true | false>(
      payload: BookmarkPayload,
      trackings?: {
        events?: Partial<
          Record<BookmarkActions | "click", Untyped extends true ? Event : UntypedEvent>
        >;
        ga4Events?: Partial<Record<BookmarkActions | "click", GA4Event>>;
        location?: BookmarkLocation;
      },
      callback?: () => void,
    ) => {
      if (trackings?.events?.click) {
        sendGATracking<true>(trackings?.events?.click, {
          untyped: true,
        });
      }
      if (trackings?.ga4Events?.click) {
        sendGA4Tracking(trackings?.ga4Events?.click);
      }
      handleMixpanelTracking(payload.action, payload.id);

      if (isLoggedIn) {
        deferRun(async () => {
          await mutate(data => create(data, payload), {
            optimisticData: data => generateNewList(data, payload),
            populateCache: true,
            revalidate: false,
            rollbackOnError: true,
          });
          setLastSync(Date.now());

          const actionEvent = trackings?.events?.[payload.action];
          if (actionEvent) {
            sendGATracking<true>(actionEvent, {
              untyped: true,
            });
          }

          const ga4ActionEvent = trackings?.ga4Events?.[payload.action];
          if (ga4ActionEvent) {
            sendGA4Tracking(ga4ActionEvent);
          }

          const snackbarProps = {
            duration: 4000,
            leftElement: (
              <StyledSnackbarMessage>
                {payload.action === "bookmark" ? "Bookmarked" : "Unbookmarked"}
              </StyledSnackbarMessage>
            ),
            rightElement: (
              <StyledSnackbarAction pathname={`${config.account.scmpAccountHost}/manage/bookmark`}>
                View all
              </StyledSnackbarAction>
            ),
          };
          handleOpenSnackbar(snackbarProps);

          if (payload.action === "bookmark") {
            toggleBookmarkRippleEffect();
            toggleShowNewBookmarkAnimation(true);
          }
          callback?.();
        });
      } else {
        const queryStringPayload: BookmarkQueryStringPayload = {
          action: {
            bookmark: payload.id,
          },
        };
        const accountUrl = new URL(window.location.href);
        accountUrl.search = qs.stringify(queryStringPayload);
        openLoginDialog({
          description: "Login or register to save your favourite articles.",
          destination: accountUrl.toString(),
          ga4CustomParameter: {
            trigger_point: "bookmark",
          },
          title: "Bookmark this story",
        });
      }
    },
    [
      isLoggedIn,
      deferRun,
      mutate,
      setLastSync,
      handleMixpanelTracking,
      handleOpenSnackbar,
      toggleBookmarkRippleEffect,
      toggleShowNewBookmarkAnimation,
      openLoginDialog,
    ],
  );

  const checkIsBookmarked = useCallback(
    (entityUuid: string) => data?.includes(entityUuid) ?? false,
    [data],
  );

  return { checkIsBookmarked, data, handleBookmarkAction, mutate };
};
