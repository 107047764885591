import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { article } from "~/data";
import type { contentLiveTagContent$key } from "~/queries/__generated__/contentLiveTagContent.graphql";

import { LiveTag } from "./styles";

type Props = {
  className?: string;
  reference: contentLiveTagContent$key;
};
export const ContentLiveTag: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const content = useFragment(
    graphql`
      fragment contentLiveTagContent on Article {
        flattenTypeIds
        liveArticle {
          status
        }
      }
    `,
    reference_,
  );

  const isLiveArticle = content.flattenTypeIds?.includes(article.types.live.entityUuid);
  const isLive = content.liveArticle?.status;
  if (!isLiveArticle || !isLive) return null;

  return <LiveTag className={className}>LIVE</LiveTag>;
};

ContentLiveTag.displayName = "ContentLiveTag";
