/**
 * @generated SignedSource<<fb5c862f2e875177a4f91bcd1240c434>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentHeadlineContent$data = {
  readonly headline: string | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"contentHeadlineTagContent" | "hooksUseContentSponsorContent">;
  readonly " $fragmentType": "contentHeadlineContent";
};
export type contentHeadlineContent$key = {
  readonly " $data"?: contentHeadlineContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentHeadlineContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentHeadlineContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentHeadlineTagContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "hooksUseContentSponsorContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "7bc3e73091fe29c28aa568457eb386e8";

export default node;
