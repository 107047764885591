import * as scmpAccount from "@product/scmp-account-sdk";
import { notEmpty, safeSetLocalStorage } from "@product/scmp-sdk";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useState } from "react";

import type { LoginDialog } from "~/components/login-dialog/atoms";
import { loginDialogAtom } from "~/components/login-dialog/atoms";
import { sendGATracking } from "~/components/tracking/google-analytics/apis";
import { sendGA4Tracking } from "~/components/tracking/google-analytics-4/apis";
import type {
  Event,
  LoginPopImpressionEventParameters,
} from "~/components/tracking/google-analytics-4/types";
import { config } from "~/data";
import { maskValue, registerEmailCookieAtom } from "~/lib/account";

type SetLoginStateProps = {
  destination: string;
  ga4CustomParameter?: LoginPopImpressionEventParameters;
} & Partial<Omit<LoginDialog, "isVisible">>;

type TrackEventProps = {
  ga4CustomParameter?: LoginPopImpressionEventParameters;
  isShow: boolean;
};

export const useLoginDialogStateHelper = () => {
  const [state, setState] = useAtom(loginDialogAtom);

  const trackEvent = useCallback(({ ga4CustomParameter, isShow }: TrackEventProps) => {
    sendGATracking({
      action: isShow ? "Login/Pop-up/Impression" : "Login/Pop-up/Close",
      category: "Login",
      label: window.location.pathname,
    });

    if (isShow && notEmpty(ga4CustomParameter)) {
      const data: Event = {
        action: "imp",
        category: "login",
        customized_parameters: ga4CustomParameter,
        subcategory: "popup",
      };

      sendGA4Tracking(data);
    }
  }, []);

  const closeLoginDialog = useCallback(() => {
    setState(current => ({
      ...current,
      description: "",
      destination: "",
      emailLoginTitle: "Sign In / Register",
      isVisible: false,
      title: "Sign In / Register",
    }));
    trackEvent({ ga4CustomParameter: undefined, isShow: false });
  }, [setState, trackEvent]);

  const openLoginDialog = useCallback(
    (loginState: SetLoginStateProps) => {
      setState({
        ...loginState,
        description: loginState.description ?? "",
        emailLoginTitle: "Sign In / Register",
        isVisible: true,
        title: loginState.title ?? "Sign In / Register",
      });
      trackEvent({ ga4CustomParameter: loginState.ga4CustomParameter, isShow: true });
    },
    [setState, trackEvent],
  );

  return {
    closeLoginDialog,
    loginDialogState: state,
    openLoginDialog,
  };
};

export const useScmpAccountEventHandlers = () => {
  const { loginDialogState: state } = useLoginDialogStateHelper();

  const updateRegisterEmailCookie = useSetAtom(registerEmailCookieAtom);
  const [unsubscribeFunctions, setUnsubscribeFunctions] = useState<(() => void)[]>([]);

  const attachEventHandlers = useCallback(() => {
    // Clear localstorage for that wall type
    if (state.wallType) {
      localStorage.removeItem(state.wallType);
    }

    const allUnsubscribeFunctions: (() => void)[] = [];
    const sendRosettaTracking = () => {
      sendGATracking({
        action: `Subscription Metering/Login Wall/Enlarged/Log In Sign Up Success Email`,
        category: "Subscription Metering",
        label: window.location.origin + window.location.pathname + window.location.search,
      });
    };

    const unsubscribeEmailLoginSuccess = scmpAccount.on(
      scmpAccount.EVENT.EMAIL_LOGIN_SUCCESS,
      () => {
        if (state.wallType) {
          sendRosettaTracking();
        }
      },
    );
    if (typeof unsubscribeEmailLoginSuccess === "function") {
      allUnsubscribeFunctions.push(unsubscribeEmailLoginSuccess);
    }

    const unsubscribeEmailRegistrationSuccess = scmpAccount.on(
      scmpAccount.EVENT.EMAIL_REGISTRATION_SUCCESS,
      (payload: scmpAccount.EmailRegistrationSuccessEventPayload) => {
        updateRegisterEmailCookie(payload.email);
      },
    );
    if (typeof unsubscribeEmailRegistrationSuccess === "function") {
      allUnsubscribeFunctions.push(unsubscribeEmailRegistrationSuccess);
    }

    const unsubscribeEmailRegistrationCompleted = scmpAccount.on(
      scmpAccount.EVENT.EMAIL_REGISTRATION_COMPLETED,
      (payload: scmpAccount.EmailRegistrationCompletedPayload) => {
        const getRegisterCallbackUrl = () => {
          const url = new URL(window.location.href);
          const centralizeRegisterUrl = new URL(
            `${config.account.registerCallbackUrl}${maskValue(payload.email)}`,
          );
          centralizeRegisterUrl.searchParams.set("source", "pwa");
          state.destination &&
            centralizeRegisterUrl.searchParams.set("destination", state.destination);

          url.searchParams.set("destination", encodeURIComponent(centralizeRegisterUrl.href));

          state.registrationTerm && url.searchParams.set(state.registrationTerm, "true");

          return url.toString();
        };
        if (state.wallType) {
          sendRosettaTracking();
        }
        location.href = getRegisterCallbackUrl();
      },
    );
    if (typeof unsubscribeEmailRegistrationCompleted === "function") {
      allUnsubscribeFunctions.push(unsubscribeEmailRegistrationCompleted);
    }

    const socialButtonClickEvents = [
      {
        event: scmpAccount.EVENT.APPLE_LOGIN_BUTTON_CLICKED,
        loginMethod: "Apple",
      },
      {
        event: scmpAccount.EVENT.GOOGLE_LOGIN_BUTTON_CLICKED,
        loginMethod: "Google",
      },
      {
        event: scmpAccount.EVENT.FACEBOOK_LOGIN_BUTTON_CLICKED,
        loginMethod: "Facebook",
      },
    ] as const;
    socialButtonClickEvents.forEach(({ event, loginMethod }) => {
      const unsubscribeSocialButtonClicked = scmpAccount.on(event, () => {
        if (!state.wallType) return;
        safeSetLocalStorage(state.wallType, loginMethod);
      });
      if (typeof unsubscribeSocialButtonClicked === "function") {
        allUnsubscribeFunctions.push(unsubscribeSocialButtonClicked);
      }
    });

    setUnsubscribeFunctions(allUnsubscribeFunctions);
  }, [state.destination, state.registrationTerm, state.wallType, updateRegisterEmailCookie]);

  const detachEventHandlers = useCallback(() => {
    if (unsubscribeFunctions.length === 0) return;
    unsubscribeFunctions.forEach(unsubscribeFunction => unsubscribeFunction());
    setUnsubscribeFunctions([]);
  }, [unsubscribeFunctions]);

  return {
    attachEventHandlers,
    detachEventHandlers,
  };
};
