import type { ResponsiveVariants } from "~/lib/styles/responsive";

export const useResponsiveImage = (
  responsiveVariant: ResponsiveVariants<{
    aspectRatio: string;
    width: number;
  }>,
) => ({
  bindResponsiveAspectRatio: {
    $aspectRatio: responsiveVariant.mobileUp.aspectRatio,
    $aspectRatioDesktopUp: responsiveVariant.desktopUp.aspectRatio,
    $aspectRatioTabletUp: responsiveVariant.tabletUp.aspectRatio,
  },
});
