import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as ContentSchemaRenderProps } from "~/components/schema-render/content";
import { ContentSchemaRender } from "~/components/schema-render/content";
import type { contentCoverVideoContent$key } from "~/queries/__generated__/contentCoverVideoContent.graphql";

type Props = {
  className?: string;
  reference: contentCoverVideoContent$key;
} & Pick<ContentSchemaRenderProps, "advertising" | "extraComponentMap" | "extraRenderFunctionMap">;

export const ContentCoverVideo: FunctionComponent<Props> = ({
  reference: reference_,
  ...props
}) => {
  const content = useFragment(
    graphql`
      fragment contentCoverVideoContent on Content {
        ... on Article {
          multimediaEmbed {
            json
          }
          ...contentSchemaRenderContent
        }
      }
    `,
    reference_,
  );

  return (
    <ContentSchemaRender reference={content} schema={content.multimediaEmbed?.json} {...props} />
  );
};

ContentCoverVideo.displayName = "ContentCoverVideo";
