import first from "lodash/first";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import type { sponsorTagSponsor$key } from "~/queries/__generated__/sponsorTagSponsor.graphql";

import type { StyledProps } from "./styles";
import {
  HomeSponsorName,
  HomeSponsorTagContainer,
  HomeSponsorTagWrapper,
  HomeSponsorType,
  SponsorImage,
  SponsorName,
  SponsorTagContainer,
  SponsorType,
} from "./styles";

export type Props = {
  className?: string;
  reference: sponsorTagSponsor$key;
  variant?: "home";
} & Partial<PropsFromStyledProps<StyledProps>>;

export const SponsorTag = forwardRef<HTMLSpanElement, Props>(
  (
    {
      className,
      color = "#999",
      gap = "4px",
      imageVariant,
      layoutAlignment = "start",
      layoutDirection = "vertical",
      nameFontSize = "14px",
      nameLineHeight = "16px",
      reference: reference_,
      typeFontSize = "14px",
      typeLineHeight = "14px",
      variant,
      ...props
    },
    reference,
  ) => {
    const sponsor = useFragment(
      graphql`
        fragment sponsorTagSponsor on Sponsor {
          name
          type
          images {
            url
          }
        }
      `,
      reference_,
    );
    const sponsorImage = first(sponsor.images);

    const renderSponsor = () => {
      if (sponsor?.name) {
        switch (variant) {
          case "home":
            return (
              <HomeSponsorTagContainer>
                <HomeSponsorTagWrapper>
                  <HomeSponsorType ref={reference} {...props}>
                    {sponsor.type}
                  </HomeSponsorType>
                  <HomeSponsorName>{sponsor.name}</HomeSponsorName>
                </HomeSponsorTagWrapper>
              </HomeSponsorTagContainer>
            );
          default:
            return (
              <SponsorTagContainer
                $color={color}
                $gap={gap}
                $imageVariant={imageVariant}
                $layoutAlignment={layoutAlignment}
                $layoutDirection={layoutDirection}
                $nameFontSize={nameFontSize}
                $nameLineHeight={nameLineHeight}
                $typeFontSize={typeFontSize}
                $typeLineHeight={typeLineHeight}
                className={className}
              >
                <SponsorType ref={reference} {...props}>
                  {sponsor.type}
                </SponsorType>
                {sponsorImage && imageVariant ? (
                  <SponsorImage
                    $aspectRatio="1"
                    $objectFit="cover"
                    alt={sponsor.name}
                    src={sponsorImage.url}
                  />
                ) : (
                  <SponsorName>{sponsor.name}</SponsorName>
                )}
              </SponsorTagContainer>
            );
        }
      } else {
        return null;
      }
    };

    return renderSponsor();
  },
);

SponsorTag.displayName = "SponsorTag";
