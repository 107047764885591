import styled from "@emotion/styled";

import { BaseImage } from "~/components/common/base-image";
import { EntityLink } from "~/components/entity-link";

import Placeholder from "./placeholder.svg";

export const StyledImage = styled(BaseImage)`
  inline-size: 100%;
  block-size: 100%;

  border-radius: 50%;

  background: #dadede;

  object-fit: cover;
  object-position: 50% 50%;
`;

export const StyledPlaceholder = styled(Placeholder)`
  border-radius: 50%;

  background: #dedede;

  filter: grayscale(100%);

  object-fit: cover;
  object-position: 50% 50%;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: inline-block;
`;

type Props = {
  $isColored?: boolean;
  $withBorderColor?: boolean;
  $withOutline?: boolean;
};

export const Figure = styled.figure<Props>`
  position: relative;

  &::before {
    position: absolute;
    inset: -4px;
    content: "";
    z-index: -1;

    border: ${props => (props.$withOutline ? "4px solid" : "none")};
    border-color: ${props => (props.$withBorderColor ? "#34b8b5" : "transparent")};
    border-radius: 50%;
  }
  ${StyledImage} {
    filter: ${props => (props.$isColored ? "none" : "grayscale(100%)")};
  }
`;
