import { useRouter } from "next/router";

export const commentHash = "#comments";

export const useArticleCommentLink = () => {
  const { asPath, replace } = useRouter();

  const isCommentLink = () => new URL(location.href).hash.includes(commentHash);

  const toggleByUrl = () => {
    const url = new URL(location.origin + asPath);
    url.hash = url.hash === commentHash ? "" : commentHash;
    void replace(url.toString(), undefined, { scroll: false });
  };

  return {
    isCommentLink,
    toggleByUrl,
  };
};
