import { BookmarkButton } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import React, { useCallback } from "react";

import type {
  OnboardingBookmarkClickEvent,
  OnboardingBookmarkSystemEvent,
} from "~/components/tracking/google-analytics-4/types";

import { useBookmark } from "./hooks";
import type { BookmarkActions, BookmarkLocation } from "./types";

type BookmarkGA4EventsParameters = {
  bookmark: OnboardingBookmarkSystemEvent;
  click: OnboardingBookmarkClickEvent;
  unbookmark: OnboardingBookmarkSystemEvent;
};

type Props = {
  className?: string;
  entityId: string;
  ga4Events?: BookmarkGA4EventsParameters;
  location?: BookmarkLocation;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  urlAlias: string;
};

export const ContentBookmark: FunctionComponent<Props> = ({
  className,
  entityId,
  ga4Events,
  location = "others",
  onClick,
  urlAlias,
}) => {
  const { checkIsBookmarked, handleBookmarkAction } = useBookmark();

  const gaLabel = urlAlias?.split("?")[0] || window.location.pathname;

  const handleClick = useCallback(
    (action: BookmarkActions, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleBookmarkAction(
        { action, id: entityId },
        {
          events: {
            bookmark: {
              action: `Bookmark/Article/Add`,
              category: "Bookmark",
              label: gaLabel,
            },
            click: { action: "Bookmark/Article/Click", category: "Bookmark", label: gaLabel },
            unbookmark: {
              action: `Bookmark/Article/Remove`,
              category: "Bookmark",
              label: gaLabel,
            },
          },
          ga4Events,
          location,
        },
      );
      onClick?.(event);
    },
    [entityId, ga4Events, gaLabel, handleBookmarkAction, location, onClick],
  );

  return (
    <BookmarkButton
      className={className}
      isBookmarked={checkIsBookmarked(entityId)}
      onClick={handleClick}
    />
  );
};

ContentBookmark.displayName = "ContentBookmark";
